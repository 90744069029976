@import '../../../theme/styles/mixins';

.quantityModifier {
  display: flex;
  flex-direction: row;
  padding: 6px;
  border-radius: 48px;
  border: 1px solid var(--colors-surfaces-primary-1);
  align-items: center;
  position: absolute;
  background-color: var(--colors-surfaces-primary-0);
  box-shadow: 0px 10px 28px getBoxShadowRGBA(0.1);
  z-index: 1;
  top: 40;
}
